import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import styled from "styled-components"
import LinkCustom from "../components/atoms/LinkCustom"
import { getTranslation } from "../utils/LanguagesUtil"

const ArticleHeaderWrapper = styled.header`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`

const ArticleHeaderTitle = styled.h1`
  margin-bottom: 0.5rem;
`

const ArticleHeaderMeta = styled.div``

const ArticleHeader = ({ node }) => (
  <ArticleHeaderWrapper className="entry-header">
    <ArticleHeaderTitle>{node.frontmatter.title}</ArticleHeaderTitle>
    {node.frontmatter.type === "Post" && (
      <ArticleHeaderMeta>
        {getTranslation("by")} Rafał Pydyniak | {node.frontmatter.date}
      </ArticleHeaderMeta>
    )}
  </ArticleHeaderWrapper>
)

const ArticleFooterNav = styled.nav`
  margin-top: 1rem;
  display: flex;
  border-top: 1px solid black;
  padding-top: 0.5rem;
`

const ArticleFooterNavPrevious = styled.span`
  flex: 1;
  font-size: 1rem;
  padding-right: 1rem;
`

const ArticleFooterNavNext = styled.span`
  flex: 1;
  font-size: 1rem;
  text-align: right;
  padding-left: 1rem;
`

const ArticleFooterLinks = ({ previous, next }) => (
  <ArticleFooterNav>
    <ArticleFooterNavPrevious>
      {previous && (
        <LinkCustom to={previous.fields.slug} rel="prev">
          <span>←</span> {previous.frontmatter.title}
        </LinkCustom>
      )}
    </ArticleFooterNavPrevious>

    <ArticleFooterNavNext>
      {next && (
        <LinkCustom to={next.fields.slug} rel="next">
          {next.frontmatter.title} <span>→</span>
        </LinkCustom>
      )}
    </ArticleFooterNavNext>
  </ArticleFooterNav>
)

const ArticleFooterWrapper = styled.footer`
  clear: both;
  font-size: 0.75rem;
`

const ArticleFooter = ({ node, previous, next }) => (
  <ArticleFooterWrapper className="entry-meta">
    {getTranslation("categories")}:{" "}
    {node.frontmatter.categories.map(category => (
      <LinkCustom
        key={category}
        to={"/category/" + category}
        rel={"category tag"}
        style={{ padding: "0.15rem" }}
      >
        {category}
      </LinkCustom>
    ))}
    <ArticleFooterLinks previous={previous} next={next} />
  </ArticleFooterWrapper>
)

export default function PageTemplate({ pageContext, data }) {
  const post = data.markdownRemark
  return (
    <Layout
      seoTitle={post.frontmatter.seoTitle}
      seoDescription={post.frontmatter.seoDescription}
    >
      <article>
        <ArticleHeader node={post} />
        <div
          className="entry-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {post.frontmatter.type === "Post" && (
          <ArticleFooter
            node={post}
            previous={pageContext.previous}
            next={pageContext.next}
          />
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        type
        categories
        seoTitle
        seoDescription
      }
      excerpt
    }
  }
`
